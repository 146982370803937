<template>
    <div class="main">
        <div class="main-container">
            <div v-if="showAppHeader && false" :class="['ask-lw-header',showSearch ? 'min' : 'max']">
                <AppHeader 
                    ref="AppHeader"
                    :inviteInfo="inviteInfo"
                    :inviteCodeCopy="inviteCodeCopy"
                    @searchBotVisible="changeSearchBotVisible" 
                    @loginOut="loginOut" 
                    @changeUserInfo="changeUserInfo"
                    @redemptionSuccess="redemptionSuccess"
                    @switchCompany="switchCompany"
                    @uploadFile="uploadFile"/>
            </div>
            <div class="ask-lw-content">
                <NewMainNav
                    ref="MainNav"
                    @searchBotVisible="changeSearchBotVisible"
                    @closeTip="closeTip"
                    :modeListObj="modeListParent"
                    :combinationInfo="combinationInfo"
                    @loginOut="loginOut"
                    @changeUserInfo="changeUserInfo"
                    @redemptionSuccess="redemptionSuccess"
                    @switchCompany="switchCompany"
                    @uploadFile="uploadFile"
                ></NewMainNav>
                <MainNav v-if="showNav && false" ref="MainNav" @searchBotVisible="changeSearchBotVisible" @closeTip="closeTip"
                    :modeListObj="modeListParent" :combinationInfo="combinationInfo" />
                <div class="ask-router-view">
<!--                  <keep-alive :include="['searchView']" v-if="keepAlive">-->
<!--                    <router-view @updateCompanyListMain="updateCompanyListMain" ref="routerView"/>-->
<!--                  </keep-alive>-->
<!--                  <router-view v-else>-->
<!--                    <router-view @updateCompanyListMain="updateCompanyListMain" ref="routerView"/>-->
<!--                  </router-view>-->
                    <keep-alive include="knowledgeView">
                        <router-view @updateCompanyListMain="updateCompanyListMain" ref="routerView"/>
                    </keep-alive>
                </div>
                <!-- 搜索 start 每个路由都可能出现 -->
                <!-- <BotView 
                    ref="BotView"   
                    v-loading="botViewLoading"
                    v-if="showSearch && !isTestSearch" 
                    @getBotBindInfo="getBotBindInfo" 
                    @searchBotVisible="changeSearchBotVisible"
                    :withHeader="true" class="ask-lw-bot" /> -->
                <!-- 搜索 end 每个路由都可能出现 -->

                <!-- 使用指南 start 不受路由限制 -->
                <UseGuide v-if="showGuide" @changeGuideVisible="changeGuideVisible" class="ask-lw-guide" />
                <!-- 使用指南 end 不受路由限制 -->
            </div>
        </div>
        <!-- v-if="showSearch && isTestSearch" -->
        <div class="main-bot" v-if="showSearch">
            <BotView 
                ref="BotView"   
                v-loading="botViewLoading"
                @getBotBindInfo="getBotBindInfo" 
                @searchBotVisible="changeSearchBotVisible"
                :withHeader="true" class="ask-lw-bot" />
        </div>
        <!-- 使用指南入口 start 每个路由都可能出现-->
        <div v-show="!showGuide && false" @click="changeGuideVisible(true)" class="ask-use-guide-enter">
            <span class="auge-icon">
                <i class="iconfont guoran-shiyongzhinan21"></i>
            </span>
            <span class="auge-text">使用指南</span>
        </div>
        <!-- 使用指南入口 end 每个路由都可能出现-->

        <!-- 同步微信消息 start -->
        <el-popover popper-class="asyn-wx-text-content-pop" placement="right" width="240" trigger="click">
            <div class="asyn-wx-text-content">
                <img v-if="isDev" width="240px" height="240px" src="./../assets/images/WechatIMG254.png" alt="" srcset="">
                <img v-if="!isDev" width="240px" height="240px" src="./../assets/images/56031680620400_.pic.jpg" alt=""
                    srcset="">
                <span class="wxcode-des">
                    微信扫码添加AskBot知识助手为微信好友，可将微信中的各类文章、文件等知识转发给AskBot，他将会自动帮您保存并学习，方便您随时间问答与知识搜索
                </span>
            </div>
            <div v-show="showNav && false" slot="reference" class="asyn-wx-text">
                <span class="auge-icon">
                    <i class="iconfont guoran-tongbu"></i>
                </span>
                <span class="auge-text">同步微信知识</span>
            </div>
        </el-popover>
        <!-- 同步微信消息 end -->

        <!-- 服务端通知 start -->
        <AppNotice ref="appNotice" @noticeUpdateDocList="noticeUpdateDocList" @updateCompanyList="updateCompanyList"/>
        <!-- 服务端通知 end -->


        <!-- 引导页 start -->
        <PopupCom v-if="welcomeVisable" @closeEvent="welcomeVisable = false" class="welcome_popup">
            <div slot="popup-con-c" style="width:100%;height:565px;padding-bottom:65px">
                <WelcomeToAigc ref="WelcomeToAigc" @closeWelcome="welcomeVisable = false" @next="nextWelcome">
                </WelcomeToAigc>
            </div>
        </PopupCom>
        <!-- 引导页 end -->

        <!-- 邀请好友 -->
        <InviteFriendsNotice 
            ref="InviteFriendsNotice"
            :appNoticeVisible.sync="appNoticeVisible"
            :inviteFriendsNoticeVisible.sync="inviteFriendsNoticeVisible"
            @changeInviteNoticeVisible="changeInviteNoticeVisible">
        </InviteFriendsNotice>

        <!-- 知识总结通知 -->
        <KnowledgeSummaryNotice 
            ref="knowledgeSummaryNotice"
            :knowledgeSummaryNoticeVisible="knowledgeSummaryNoticeVisible" 
            :appNoticeVisible="appNoticeVisible"
            @changeKnowledgeSummaryNoticeVisible="changeKnowledgeSummaryNoticeVisible"
            @searchBotVisible="changeSearchBotVisible">
        </KnowledgeSummaryNotice>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import MainNav from "./testComponents/MainNav.vue";
import AppHeader from "./testComponents/AppHeader.vue";
import BotView from "./testComponents/BotView.vue";
import UseGuide from "./testComponents/UseGuide.vue";
import AppNotice from "./testComponents/AppNotice.vue";
import { requestUrl } from "./../http/requestUrl";
import PopupCom from '@/components/PopupCom.vue';
import WelcomeToAigc from './WelcomeToAigc.vue';
import KnowledgeSummaryNotice from './testComponents/knowledgeSummaryNotice.vue';
import InviteFriendsNotice from './testComponents/InviteFriendsNotice.vue'
import NewMainNav from "@/views/newVersionView/newMainNav";
import { getBotInfo } from "@/utils/getBotInfo";

export default {
    name: "MainView",
    components: { NewMainNav, MainNav, AppHeader, BotView, UseGuide, AppNotice, PopupCom, WelcomeToAigc, KnowledgeSummaryNotice, InviteFriendsNotice },

    data() {
        return {
            // 是否展示侧边导航
            showNav: false,
            // 是否展示搜索 点击顶部搜索展示
            showSearch: false,
            // 是否展示引导 点击右侧引导入口展示
            showGuide: false,
            // 接收服务端通知 Eventsource
            appEvt: null,
            // 账号首次进入弹窗
            welcomeVisable: false,
            wxUrl: "./../assets/images/WechatIMG254.png",
            showAppHeader:false, // 是否展示顶部导航
            knowledgeSummaryNoticeVisible:false, // 知识总结notice显隐
            appNoticeVisible:false,
            inviteFriendsNoticeVisible:false,
            inviteInfo:{},
            inviteCodeCopy:'',
            keepAlive:false
        }
    },
    computed: {
        ...mapState({
            // 智能写作次数/容量 套餐使用信息
            combinationInfo: state => state.user.combinationInfo,
        }),
        ...mapGetters('user', {
            // 模板列表
            modeListParent: 'navModeList',
        }),
        ...mapGetters('apphandle', {
            // 是否触发顶部添加知识
            isTopAddDoc: 'getIsTopAddDoc',
        }),
        ...mapGetters('apphandle', {
            // 是否触发顶部添加知识
            isNeedSaveTemplate: 'getIsNeedSaveTemplate',
        }),
        ...mapGetters('user', {
            // 模板列表
            isNeedSaveTemplate: 'getIsNeedSaveTemplate',
        }),
        // 是否展示顶部导航
        // showAppHeader(){
        //     return this.showNav
        // }
        ...mapGetters('apphandle', {
            // 助手显隐
            isTopSearch: 'getIsTopSearch',
        }),
        ...mapGetters('apphandle', {
            // 助手loading
            botViewLoading: 'getBotViewLoading',
        }),
        // ...mapGetters('apphandle', {
        //     isTestSearch: 'getIsTestSearch',
        // }),
        ...mapGetters('apphandle', { // 是否是切换企业
            isSwitchCompany: 'getIsSwitchCompany'
        }),
    },
    methods: {
        ...mapActions('user', [
            'updateUserModeInfo'
        ]),

        ...mapActions('notice', [
            'updateWxAutoUpdateTimeStamp'
        ]),

        ...mapActions('apphandle', [
            'updateIsTopSearch'
        ]),
        ...mapActions('user', [
            "updateSearchDocModeId"
        ]),

        ...mapActions('user', [
            'updateCombinationInfo'
        ]),
        ...mapActions('user', [
            'updatePayStatus'
        ]),
         ...mapActions('user', [
            'updateIsLoginOut'
        ]),
        // ...mapActions('apphandle', [
        //     'updateIsKnowledgeSummaryNotice'
        // ]),
        // ...mapActions('apphandle', [
        //     'updateIsTestSearch'
        // ]),
        ...mapActions('apphandle', [
            'updateIsNeedUpdateCompanyList'
        ]),

        // 通知更新侧边栏企业列表
        updateCompanyListMain(){
            this.$refs.MainNav.getAllCompany();
        },

        /**
         * 修改搜索机器人显示隐藏状态
         * @method changeSearchBotVisible
         * @param {boolean} status 显示/隐藏 搜索机器人
         * @return
         */
        changeSearchBotVisible(status) {
            console.log(status);
            this.showSearch = status;
            this.updateIsTopSearch(status);
            
            if (this.showGuide) {
                this.showGuide = false;
            }
            // console.log(this.isTestSearch,'this.isTestSearch');
            if(this.showSearch){
                document.querySelector('.el-drawer__wrapper').style.right = '390px';
            } 
            if(!status){
                this.$refs.AppHeader.screenWidthTimer = false;
                this.$refs.AppHeader.screenWidth();
                
            }
        },
        /**
         * 子组件 【AppHeader】调用 退出登录
         * @method loginOut
         * @param {} 
         * @return 跳转至登录界面
         */
        loginOut() {
            let toUrlArr = location.href.split("/");
            toUrlArr[toUrlArr.length - 1] = "login";
            console.log(this.isNeedSaveTemplate,'isNeedSaveTemplate');
            if(!this.isNeedSaveTemplate){
                let isLishi = sessionStorage.getItem('isLishi');
                this.$http.delete(requestUrl.newAuth.clientLoginOut).then((res) => {
                    if (res.status === 200) {
                        localStorage.clear();
                        sessionStorage.clear();
                        if (isLishi == "1") {
                            window.location = toUrlArr.join("/") + "?source=lishi";
                        } else {
                            window.location = toUrlArr.join("/");
                        }
                        
                        // localStorage.clear()
                        // sessionStorage.clear()
                    } else {
                        this.messageFn('error', res.message);
                    }
                });
            } else {
                this.updateIsLoginOut(true)
            }
            
        },
        /**
         * 子组件 【AppHeader】调用 修改用户头像 / realName
         * @method changeUserInfo
         * @param {object} userInfo 
         * @param {function} coallback 
         * @return 修改用户信息
         */
        changeUserInfo(userInfo, coallback) {
            this.$http
                .put("/api/account/appoint/" + localStorage.getItem("_uid"), userInfo)
                .then((res) => {
                    if (res.data.code === "0") {
                        // this.messageFn('success', '修改成功！');
                        coallback();
                    }
                });
        },
        /**
         * 修改引导 展示 引导/搜索 同时只能有一个展示
         * @method changeGuideVisible
         * @param {boolean} status
         * @return 修改引导/搜索展示状态
         */
        changeGuideVisible(status) {
            if (status) {
                if (this.showSearch) {
                    this.showSearch = false;
                }
            }
            this.showGuide = status;
        },
        /**
         * 获取接入机器人信息
         * @method getBotBindInfo
         * @param {function} callback
         * @return
         */
        getBotBindInfo(callback) {
          if (sessionStorage.getItem('isClient')){
            return
          }
          getBotInfo((res) => {
            callback(res);
          })
        },
        /**
         * 接收监听来自服务端传入的消息 应用初始化时启用
         * @method initAskAppEventSource
         * @param {} 
         * @return
         */
        initAskAppEventSource() {
            setTimeout(() => {
                this.appEvt && this.appEvt.close();
                let _this = this;
                // if(this.$route.path !== '/payVersion'){
                let _uid = localStorage.getItem("_uid");
                if (typeof _uid === 'object'){
                  _uid = _uid.data;
                }
                this.appEvt = new EventSource('/knowledge-api/global/sse-emitter?userId=' + _uid);
                // }
                this.appEvt.addEventListener("notice", function (e) {
                    console.log(e.data)
                    console.log("[MainView] notice 157: ", e);
                })
                this.appEvt.addEventListener("update", function (e) {
                    console.log(e.data)
                    console.log("[MainView] update 161: ", e);
                })
                this.appEvt.addEventListener("message", function (e) {
                    let resObj = JSON.parse(e.data);
                    if (resObj.type === 'CHAT_SYNC' && resObj.payload === "ok") {
                        _this.$refs.appNotice.closeUploadInfoVisible(true);
                    }
                    // 微信文件同步失败通知
                    // console.log(256, ": ",resObj);
                    if (resObj.type === 'VIP_CHAT_SYNC_FAIL' || resObj.type === 'NO_VIP_CHAT_SYNC_FAIL') { // 文件超大，需升级会员
                        _this.$refs.appNotice.closeUploadFailInfoVisible(true, resObj.type);
                    }
                    if (resObj.type === 'CAPACITY' || resObj.type === 'FLOW' || resObj.type === 'FLOW_DILATATION_PASS' || resObj.type === 'FLOW_DILATATION_REFUSE' || resObj.type === 'CAPACITY_DILATATION_PASS' || resObj.type === 'CAPACITY_DILATATION_REFUSE') {
                        if(resObj.type === 'FLOW_DILATATION_PASS' || resObj.type === 'FLOW_DILATATION_REFUSE' || resObj.type === 'CAPACITY_DILATATION_PASS' || resObj.type === 'CAPACITY_DILATATION_REFUSE'){
                            _this.$refs.appNotice.applyVisible = true;
                            _this.$refs.appNotice.getFlowAndCapacityList(resObj);
                        }
                        if(resObj.type === 'CAPACITY' || resObj.type === 'FLOW' || resObj.type === 'FLOW_DILATATION_PASS' || resObj.type === 'CAPACITY_DILATATION_PASS'){
                            _this.updateCombinationInfo(resObj.payload);
                        }
                    } else  if (resObj.type === 'USER_PAY_SUCCESS') {
                        console.log('支付成功');
                        _this.updatePayStatus('success');
                        
                    } else if (resObj.type === 'USER_PAY_FAIL') {
                        console.log('支付失败');
                        _this.updatePayStatus('fail');
                    } else if (resObj.type === 'SUMMARY') {
                        // 知识总结
                        _this.appNoticeVisible = _this.$refs.appNotice.uploadInfoVisible;
                        // 总结notice弹出后,并且更新同步知识的notice关闭时,刷新首页知识列表
                        if(!_this.appNoticeVisible){
                            _this.noticeUpdateDocList(new Date().getTime(),_this.appNoticeVisible);
                        }
                        _this.$refs.knowledgeSummaryNotice.getKnowledgeSummaryNoticeList(resObj);
                    } else if (resObj.type === 'INVITE_GIFT_VIP') {
                        console.log(resObj,'resObj');
                        resObj.payload = JSON.parse(resObj.payload);
                        // 推送用户赠VIP消息
                        _this.appNoticeVisible = _this.$refs.appNotice.uploadInfoVisible;
                        // 总结notice弹出后,并且更新同步知识的notice关闭时,刷新首页知识列表
                        if(!_this.appNoticeVisible){
                            _this.noticeUpdateDocList(new Date().getTime(),_this.appNoticeVisible);
                        }
                        _this.$refs.InviteFriendsNotice.getInviteFriendsNoticeList(resObj);
                        _this.getInviteInfo();
                        _this.$refs.MainNav.selectUserBuyAbility();
                    } else if (resObj.type === 'JOIN_COMPANY_SUCCESS' || resObj.type === 'JOIN_COMPANY_WAIT_AUDIT' || resObj.type === 'JOIN_COMPANY_AUDIT_PASS' || resObj.type === 'JOIN_COMPANY_AUDIT_REFUSE' ) {
                        _this.$refs.appNotice.joinCompanyVisible = true;
                        _this.$refs.appNotice.getJoinCompanyList(resObj);
                        if(resObj.type === 'JOIN_COMPANY_AUDIT_PASS'){
                            _this.$refs.AppHeader.getAllCompany();
                            if (_this.$route.path === '/joinCompany'){
                                _this.updateIsNeedUpdateCompanyList(true)
                            }
                        }
                    }
                })
                this.appEvt.onerror =  (e) => {
                    console.log("[MainView] 166: ", e);
                    this.appEvt.close();
                    this.initAskAppEventSource();
                }

            }, 2000);

            // setTimeout(() => {
            //     this.$refs.appNotice.closeUploadInfoVisible(true);
            // }, 15000);
        },
        // 更新知识列表
        noticeUpdateDocList(timeStamp,flag) {
            if(!flag){
                this.$refs.appNotice.closeUploadInfoVisible(false);
            }
            this.$store.dispatch('notice/updateWxAutoUpdateTimeStamp', timeStamp);
        },
        nextWelcome() {
            let closeBtn = document.getElementsByClassName('add-automtated-closeBtn')[0]
            closeBtn.style.display = 'flex';
            let welcome_popup = document.getElementsByClassName('welcome_popup')[0]
            welcome_popup.scrollTop = 0
        },
        closeTip() {
            this.$refs.appNotice.closeUploadInfoVisible(false);
            this.$refs.appNotice.closeUploadFailInfoVisible(false);
        },
        // 兑换码兑换成功
        redemptionSuccess(){
            this.$refs.MainNav.getVerInfo();
        },
        // 修改知识总结显隐状态
        changeKnowledgeSummaryNoticeVisible(status){
            this.knowledgeSummaryNoticeVisible = status;
            if (this.showGuide) {
                this.showGuide = false;
            }
        },
        // 修改知识总结显隐状态
        changeInviteNoticeVisible(status){
            this.inviteFriendsNoticeVisible = status;
            if (this.showGuide) {
                this.showGuide = false;
            }
        },
        //  获取邀请信息
		getInviteInfo(){
			this.$http.get(requestUrl.invite.getInfo + "?client=web").then((res) => {
				if (res.data.code === "0") {
                    this.inviteCodeCopy = `使用AskBot智能助手，轻松收集和整理微信、电脑或手机内的各类知识，AI自学并总结重点。通过对话你能随时找到所需内容，AIGC还能助力生成分享新知识。让知识倍增，快来试试吧！更多功能，请PC端体验。以上文案由AIGC自动生成。${res.data.data.inviteWebUrl}`
                    this.handlerInviteTips(res.data.data);
				}
			});
		},
        handlerInviteTips(data){
            data.tipsTextPopup = '';
            data.tipsText = '';
            // data.inviteCount = 7;
            if(data.inviteCount === 0){
                data.tipsTextPopup = '成功邀请1人获得30天VIP权限';
                data.tipsText = '邀请1人获得1个月VIP';
            } else  if(data.inviteCount === 1){
                data.tipsTextPopup = '再成功邀请2人获得30天VIP权限';
                data.tipsText = '再邀请2人获得1个月VIP';
            } else  if(data.inviteCount > 1 && data.inviteCount < 3){
                data.tipsTextPopup = '再成功邀请1人获得30天VIP权限';
                data.tipsText = '再邀请1人获得1个月VIP';
            } else  if(data.inviteCount === 3){
                data.tipsTextPopup = '再成功邀请3人获得30天VIP权限';
                data.tipsText = '再邀请3人获得1个月VIP';
            } else  if(data.inviteCount >= 3 && data.inviteCount < 6){
                if(data.inviteCount === 4){
                    data.tipsTextPopup = '再成功邀请2人获得30天VIP权限';
                    data.tipsText = '再邀请2人获得1个月VIP';
                } else {
                    data.tipsTextPopup = '再成功邀请1人获得30天VIP权限';
                    data.tipsText = '再邀请1人获得1个月VIP';
                }
            }
            this.inviteInfo = data;
        },
        
        // 保存邀请记录（用于测试
        saveInvite(){
            this.$http.post(requestUrl.invite.saveInvite,{
                "inviteCode": "576b9ce48fd581b0b7cd39b247cad9db", //邀请码
                "invitee": "2163", //被邀请人id
                "inviteeMainId": "421139a0ca5e40a5a1261b7ca08fb08c", //被邀请人主体id
                "inviteeMobile": "16619968440", //被邀请人手机号
                "client": "web"
            }).then((res) => {
                    if (res.data.code === "0") {
                       this.messageFn('success', '保存邀请记录成功');
                        // this.$refs.InviteFriendsNotice.getInviteFriendsNoticeList();
                    } else {
                        this.messageFn('error', res.message);
                    }
                });
        },
        // 切换企业
        switchCompany(){
            this.showSearch = false;
            this.initFn();
            this.handlerShowGuide();
            this.$nextTick(() => {
                // this.$refs.AppHeader.handlerSwitchCompany();
                //
                // this.$refs.MainNav.updateNavList();
                // this.$refs.routerView.selectUserBuyAbility();//更新版本信息,容量,流量
                // this.$refs.routerView.getDocList({},true); // 更新知识列表
                // this.$store.dispatch('user/updateUserModeInfo'); // 更新模版列表
                // this.$refs.routerView.ckeditorVisible = false;
                // this.$refs.routerView.updateIsTopSearch(false);
                // if (this.$route.path === '/index'){
                //   this.$refs.routerView.$refs.newsSummary.getPinToTop();
                //   this.$refs.routerView.$refs.newsSummary.getDirectory();
                // }

            })
        },
        // 引导显隐处理
        handlerShowGuide(){
            if (sessionStorage.getItem('loginSuccessTimes') == '1' && (localStorage.getItem('accountSource') != 11 && localStorage.getItem('accountSource') != 12) && sessionStorage.getItem('isLogon') == 1) {
                sessionStorage.removeItem('isLogon');
                this.welcomeVisable = true;
                this.$nextTick(() => {
                    if (this.$refs.WelcomeToAigc && this.$refs.WelcomeToAigc.page == '1') {
                        let closeBtn = document.getElementsByClassName('add-automtated-closeBtn')[0]
                        closeBtn.style.display = 'none';
                    }
                })
                sessionStorage.removeItem('loginSuccessTimes')
            }
        },
        initFn(){
            if (this.$route.path === '/login' || this.$route.path === '/auth') {
                this.showNav = false;
                this.showAppHeader = false;
            } else {
                if (this.$route.path === '/joinCompany' ){
                    this.showNav = false;
                    this.showAppHeader = true;
                } else {
                    this.showAppHeader = true;
                    this.showNav = true;
                    if(this.$route.path === '/index'){
                        if(sessionStorage.getItem('isFirstGoIndex') && sessionStorage.getItem('isFirstGoIndex') == 1){
                            this.$refs.knowledgeSummaryNotice.knowledgeSummaryList = [];
                            this.$refs.knowledgeSummaryNotice.getKnowledgeSummaryNoticeList();
                            this.$refs.InviteFriendsNotice.inviteFriendsList = [];
                            this.$refs.InviteFriendsNotice.getInviteFriendsNoticeList();
                            this.$refs.appNotice.applyNoticeList = [];
                            this.$refs.appNotice.getFlowAndCapacityList();
                            this.$refs.appNotice.joinCompanyList = [];
                            this.$refs.appNotice.getJoinCompanyList();
                            this.getInviteInfo();
                            // sessionStorage.removeItem('isFirstGoIndex')
                        }
                        this.$nextTick(() => {
                            this.$refs.MainNav.activeNavId = 'index';
                        })
                    }
                }
                this.$nextTick(() => {
                    // 手动刷新页面重新连接
                    this.initAskAppEventSource();
                })
            }
            if (location.host.indexOf("test") !== -1 || location.host.indexOf("localhost") !== -1) {
                this.isDev = true;
            } else {
                this.isDev = false;
            }
        },
        // 接收到加入公司的推送,刷新公司列表
        updateCompanyList(){
            this.$refs.AppHeader.getAllCompany()
        },
      getCompany(){
        let uid = localStorage.getItem('_uid')
        let url = "/portal-api/department/corps4main?userId=" + uid;
        this.$http.get(url).then((res) => {
          if (res.data.code == '0'){
            let corpType = res.data.data[0] ? res.data.data[0].corpType : 9;
            let authType = res.data.data[0] ? res.data.data[0].authType : '99';
            localStorage.setItem('isWeChat',corpType == 0 && authType == 0 ? 'true' : 'false')
          }
        })
      },
      uploadFile(){
        if (this.$route.path === '/index'){
          this.$refs.routerView.$refs.DragUpload.clickDrag()
        }
      }
    },
    watch: {
        $route(to, from) {
            console.log('上一个路由：' , to,from);
            if (to.path === '/login') {
                this.$refs.InviteFriendsNotice.inviteFriendsNoticeVisible = false;
                this.knowledgeSummaryNoticeVisible = false;
                this.inviteFriendsNoticeVisible = false;
                this.showNav = false;
                this.showSearch = false;
                this.showAppHeader = false;
                // 关闭接收服务端推送通知 
                this.appEvt && this.appEvt.close();
                this.$refs.appNotice.closeUploadInfoVisible(false);
            } else {
                this.showAppHeader = true;
                this.showNav = true;
                if(to.path !== '/auth'){
                    this.getInviteInfo();
                }
            }
            if (from.path === '/emptyPage'){
              this.keepAlive = false
            } else {
              this.keepAlive = true
            }
            // if (from.path === '/auth') {
            //     console.log(283);
            //     this.initAskAppEventSource();
            // }
            if (to.path !== '/index') {
                console.log(294);
                if(to.path === '/payVersion'){
                    this.updatePayStatus('');
                }
                this.updateSearchDocModeId("");
            } else {
                console.log(290);
                
                this.handlerShowGuide();
                // this.$refs.AppHeader.handlerSwitchCompany();
                this.appEvt && this.appEvt.close();
                this.initAskAppEventSource();
                if(sessionStorage.getItem('isFirstGoIndex') && sessionStorage.getItem('isFirstGoIndex') == 1){
                    this.$refs.knowledgeSummaryNotice.knowledgeSummaryList = [];
                    this.$refs.knowledgeSummaryNotice.getKnowledgeSummaryNoticeList();
                    this.$refs.InviteFriendsNotice.inviteFriendsList = [];
                    this.$refs.InviteFriendsNotice.getInviteFriendsNoticeList();
                    this.$refs.appNotice.applyNoticeList = [];
                    this.$refs.appNotice.getFlowAndCapacityList();
                    this.$refs.appNotice.joinCompanyList = [];
                    this.$refs.appNotice.getJoinCompanyList();
                    this.getInviteInfo();
                    sessionStorage.removeItem('isFirstGoIndex')
                }
                this.$nextTick(() => {
                    this.$refs.MainNav.updateNavList()
                    this.$refs.MainNav.activeNavId = 'index';
                })
            }
            if (to.path === '/joinCompany' ){
                this.showNav = false;
                this.showAppHeader = true;
            }
        },
        isTopAddDoc(val) {
            console.log(val);
            this.$refs.appNotice.closeUploadInfoVisible(false);
            this.$refs.appNotice.closeUploadFailInfoVisible(false);
        },
        isTopSearch(n){
            this.showSearch = n;
        },
        botViewLoading(n){
            if(n){
                if(this.showSearch){
                    this.$refs.BotView.getBotBindInfo();
                }
            }
        },
        showSearch(n){
            document.querySelector('.asyn-wx-text').style.right = n ? '388px' : '0'
        },
        // isSwitchCompany(n,o){
        //     console.log(n,o,'isSwitchCompany-mainView');
        //     if(n){
        //         this.initAskAppEventSource(); // 切换企业时,eventSource重连
        //         this.handlerShowGuide()
        //     }
        // },
    },
    mounted() {
        this.initFn();
        this.getCompany();
    },
    created() {
        // 获取模版列表
        // if (this.$route.path !== '/login' || this.$route.path !== '/auth') {
        //     this.$store.dispatch('user/updateUserModeInfo');
        // }
        // 获取 智能写作次数/容量 套餐使用信息
        // this.$store.dispatch('user/updateCombinationInfo');
    }
}
</script>
<style lang="less">
@import "./../assets/less/common.less";

.asyn-wx-text-content-pop {
    padding: 0 !important;

    .asyn-wx-text-content {
        .wxcode-des {
            margin: 0px 12px 10px;
            display: block;
            font-size: 12px;
            color: #A9B3C6;
        }
    }
}


.main {
    height: 100%;
    display: flex;
    // flex-direction: column;
    justify-content: flex-start;
    min-width: 1300px;
    min-height: 600px;
    position: relative;
    .main-container{
        flex:1;
    }

    .asyn-wx-text {
        position: absolute;
        right: 0;
        top: 150px;
        width: 34px;
        height: 132px;
        background: linear-gradient(179deg, #366AFF, #59EAFF);
        border-radius: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        color: white;
        align-items: center;
        cursor: pointer;

        .auge-icon {
            margin-top: 10px;

            i {
                font-size: 13px;
            }
        }

        .auge-text {
            margin-top: 4px;
            font-weight: 700;
            writing-mode: vertical-lr;
        }
    }

    .ask-use-guide-enter {
        position: absolute;
        top: 150px;
        right: 0;
        width: 34px;
        height: 112px;
        background: linear-gradient(179deg, #366AFF, #59EAFF);
        border-radius: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        color: white;
        align-items: center;
        cursor: pointer;

        .auge-icon {
            margin-top: 16px;

            i {
                font-size: 13px;
            }
        }

        .auge-text {
            margin-top: 4px;
            font-weight: 700;
            writing-mode: vertical-lr;
        }
    }

    .ask-lw-header {
        flex: none;
        height: 60px;
        background-color: white;
        &.min{
            width: calc(100% - 16px);
            border-radius: 6px;
        }
        &.max{
           width:100%; 
           border-radius: 0;
        }
    }

    .ask-lw-content {
        //height: calc(100% - 60px);
      height: 100%;
        flex: auto;
        display: flex;
        justify-content: space-between;

        .ask-router-view {
            flex: auto;
            background-color: #F5F7FB;
        }

       
        .ask-lw-guide {
            flex: none;
            width: 387px;
            background: white;
            box-shadow: 0px 0px 10px 0px rgba(79, 82, 92, 0.17);
            border-radius: 5px;
            margin: 16px 16px 16px 0;
        }
    }
    .ask-lw-bot {
        flex: none;
        width: 387px;
        background: #F6F8FB;
        box-shadow: 0px 0px 10px 0px rgba(79, 82, 92, 0.17);
        border-radius: 5px;
        margin: 16px 16px 16px 0;
    }
    .main-bot{
        height: 100%;
        .ask-lw-bot {
            margin: 0;
            height: 100%;
        }

    }

}

.welcome_popup {
    #popup-assembly {
        width: 1000px !important;
    }

    .dialog-footer {
        display: none !important;
    }
}

.switch-company-loading{
    .el-icon-loading,.el-loading-text{
        font-size: 30px;
        color: #366AFF;
    }
    .el-icon-loading{
        margin-bottom: 30px;
    }

}
</style>