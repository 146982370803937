<template>
    <div class="popup-floating-layer" @click="() => { }">
        <div class="defaultDialog">
            <div id="popup-assembly">
                <div class="popup-header">
                    <div class="popup-name">
                        <slot name="popup-name"></slot>
                    </div>
                    <div class="popup-tip">
                        <slot name="popup-tip"></slot>
                    </div>
                </div>
                <div class="popup-container">
                    <slot name="popup-con"></slot>
                </div>
                <div class="popup-container-c">
                    <slot name="popup-con-c"></slot>
                </div>
                <div class="add-automtated-closeBtn" @click.stop="closeEvent">
                    <i class="arsenal_icon arsenalcuo1"></i>
                </div>
                <div class="dialog-footer">
                    <slot name="dialog-footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PopupCom",
    data() {
        return {

        }
    },
    methods: {
        closeEvent() {
            this.$emit('closeEvent')
        }
    }
}
</script>

<style scoped lang="less">
@keyframes anim-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.popup-floating-layer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 2000;
    /*display: flex;
        align-items: center;
        justify-content: center;*/
    animation: dialog-fade-in 0.3s ease-in;

    /deep/.el-button {
        padding: 8px 30px !important;
    }

    .defaultDialog {
        position: relative;
        margin: 0 auto;
        margin-top: 8vh;

        /*display: flex;
            justify-content: center;*/
        .color-style {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .color-item-style {
                width: 38px;
                height: 38px;
                border-radius: 5px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                .arrow {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 0;
                    border-top: 10px solid;
                    /*border-top-left-radius: 5px;*/
                    border-right: 10px solid transparent;
                }
            }
        }

        .status-propertied {
            height: 40px;
            background-color: #FBFCFD;
            border-radius: 5px;
            padding-left: 12px;
        }

        .next-status {
            background-color: #FBFCFD;
            border-radius: 5px;
            padding-left: 12px;
            /*padding-bottom: 12px;*/
        }

    }

    #popup-assembly {
        width: 646px;
        background-color: white;
        border-radius: 5px;
        position: relative;
        margin: 0 auto;

        .popup-header {
            padding-top: 34px;
            padding-bottom: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .popup-name {
                font-size: 26px;
                >div {
                    font-size: 26px;
                }
            }

            .popup-tip {
                font-size: 14px;
                color: #A9B3C6;
                padding-top: 18px;
                >div {
                    font-size: 14px;
                    color: #A9B3C6;
                }
            }
        }

        .popup-container {
            max-height: calc(84vh - 200px);
            overflow-y: scroll;
            padding: 0 64px;

            /* min-height: 300px;*/
            .popup-con {
                /*font-size: 14px;*/
                color: #616161;

                /deep/.order-field-list {
                    display: flex;
                    flex-wrap: wrap;
                }

                /deep/.field-item-class:nth-child(1) {
                    .field-name {
                        padding-left: 0px !important;
                    }

                    .el-cascader {
                        margin-left: 0px !important;
                    }

                    .el-select {
                        margin-left: 0px !important;
                    }
                }

                /deep/.field-item-class {
                    .el-cascader {
                        line-height: 0;
                    }
                }

                /deep/.field-item-class:nth-child(2) {
                    .field-name {
                        padding-left: 30px;
                        line-height: 20px;
                    }

                    .el-cascader {
                        margin-left: 30px;
                    }

                    .el-select {
                        margin-left: 30px;
                    }
                }

                /*/deep/.field-item-class:nth-child(even){
                        .field-name{
                            padding-left: 30px;
                            line-height: 20px;
                        }
                        .el-cascader{
                            margin-left: 30px;
                        }
                        .el-select{
                            margin-left: 30px;
                        }
                    }*/
                /deep/.radio-class {
                    background: #FBFCFD;
                    border-radius: 5px;
                    padding-left: 12px;
                    min-height: 40px;
                    display: flex;
                    align-items: center;
                }

                /deep/.field-name {
                    padding-bottom: 8px;
                    line-height: 20px;
                }

                /deep/.field-item-class {
                    padding-bottom: 20px;
                    width: calc(50% - 30px);
                    padding-right: 30px;
                }

                /deep/.field-item-class-c {
                    width: 100%;
                    padding-bottom: 20px;
                }
            }

        }

        .popup-container-c {
            padding: 0 64px;

            .popup-con-c {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .popup-con-panel {
                    background: #FFFFFF;
                    border: 1px solid #E0E6F7;
                    border-radius: 5px;
                    width: 248px;
                    flex: none;
                    position: relative;

                    .popup-con-panel-title {
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        /*  border: 1px solid #E0E6F7;*/
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        border-bottom: 1px solid #E0E6F7;
                    }

                    .left-title {
                        background-color: #F5F7FB;
                    }

                    .right-title {
                        background-color: #759FFF;
                        color: white;
                    }

                    .popup-con-panel-con {
                        height: calc(84vh - 300px);
                        min-height: 340px;
                        overflow-y: scroll;
                        overflow-x: hidden;
                        padding: 20px 0;

                        #filterResort {
                            padding-bottom: 30px;
                        }

                        .popup-con-panel-con-item {
                            display: flex;
                            height: 38px;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0 20px;
                            /* font-size: 14px;*/
                            color: #616161;

                            .iconfont {
                                display: none;
                            }

                            .handle {
                                cursor: move;
                            }
                        }

                        .popup-con-panel-con-item:hover {
                            background-color: #F6F8FD;

                            .iconfont {
                                display: block;
                                color: #366AFF;
                                cursor: pointer;
                            }
                        }
                    }

                    .popup-con-panel-tip {
                        position: absolute;
                        bottom: 0;
                        height: 38px;
                        width: 100%;
                        background: #F6F8FD;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #616161;
                        font-size: 12px;
                    }
                }
            }


        }

        .add-automtated-closeBtn {
            width: 30px;
            height: 30px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.21);
            border-radius: 50%;
            position: absolute;
            right: -8px;
            top: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            i {
                font-size: 18px;
                color: #616161;
            }
        }

        .dialog-footer {
            padding-bottom: 30px;
            padding-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .delete-footer {
                position: absolute;
                left: 64px;

                .delete-btn {
                    width: 28px;
                    height: 28px;
                    background: #FFFFFF;
                    border: 1px solid #E0E6F7;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }

                .el-button {
                    min-width: 89px;
                }
            }

            .cancel-btn {
                min-width: 99px;
                height: 35px;
                background: #FFFFFF;
                border: 2px solid #E0E6F7;
                border-radius: 19px;
                padding: 0;
                border-radius: 19px;
                cursor: pointer;
                
            }

            .confirm-btn {
                height: 35px;
                min-width: 99px;
                background: #366AFF;
                padding: 0;
                border-radius: 19px;
                border: 2px solid #366AFF;
                margin-left: 10px;
                color: #fff;
                cursor: pointer;
                &.is-loading {
                    border-color: #7aa3ff;
                }
            }
        }
    }
}
</style>