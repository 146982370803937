<!--
   整体的预览组件，类型比较杂乱
   VIDEO -- 视频,音频
   HTML -- 语雀，自建，微信同步消息，md 文件都可能归类于html形式用v-html展示
   source 为 CREATED_BY_ONESELF 来源是 自建应用的，和HTML 做同类型区分，因为后期可能要支持html格式的上传
   fileType 定义为WECHAT 是为兼容微信同步文件被删除在回收站中自动预览的问题。
   暂时，其余的类型都执行预览项目
-->
<template>
    <div class="file_pre" id="file_pre">
        <template v-if="(tagIds.length == 0 && !withLocationMark) || (newVersion && newFileType === 'PPT')">
            <template v-if="fileType == 'VIDEO'">
                <video class="video" :src="url" controls></video>
            </template>
            <template v-else-if="fileType == 'HTML' || source == 'CREATED_BY_ONESELF'">
                <div class="preview_wechat" style="text-align:left;" v-html="fileText" @click="downLoad"></div>
                <!-- <iframe  class="preview_iframe" :src="url" frameborder="0"></iframe> -->
            </template>
            <!-- <template v-else-if="fileType == 'WECHAT'">
                <div class="wechat_view">
                    <img src="../../assets/images/empty.png" alt="">
                    <p>该知识已删除，点击可查看原文</p>
                    <div class="to_link" @click="toView">
                        立即查看
                    </div>
                </div>
            </template> -->
            <template v-else-if="url || (newVersion && newFileType === 'PPT')">
                <iframe class="preview_iframe"  :src="previewUrl" style="border:none;" :style="{height: iframeHeight}"></iframe>
              <template v-if="!loading && (newFileType === 'PPT' && pageChunkData.length > 1)">
                <div id="pagination">
                  <el-pagination :current-page="currentPage + 1" @current-change="currentChange" @prev-click="prev"
                                 @next-click="next" layout="slot, prev, pager, next" :page-size="1" :total="pageChunkData.length">
                    <span class="total-class">答案由{{ pageChunkData.length }}段内容生成</span>
                  </el-pagination>
                </div>
              </template>
            </template>
        </template>
        <template v-else-if="tagIds.length != 0 && newVersion === false">
            <PdfView ref="pdf_view" :tagIds="tagIds" :textWatermarkStr="textWatermarkStr"></PdfView>
        </template>
        <new-pdf-view v-else-if="tagIds.length != 0 && newVersion" :knowledge-id="knowledgeId" :tagIds="tagIds" :url="previewHref"></new-pdf-view>
        <template v-else-if="withLocationMark">
            <markPdf ref="pdf_view" :withLocationMark="withLocationMark"></markPdf>
        </template>
        <!-- <PdfView :tagIds="tagIds"></PdfView>  -->
        <!-- </div> -->
        <ElImageViewer
            :zIndex="999999"
            v-if="showPreview"
            class="imgaeViewer"
            :on-close="closeViewer"
            :url-list="imgList">
        </ElImageViewer>
        <div class="loading_img" v-show="loading">
            <img src="../../assets/loading.gif" alt="">
        </div>
    </div>
</template>
<script>
// import { Base64 } from "js-base64";
import { requestUrl } from "../../http/requestUrl";
import { v4 as uuidv4 } from "uuid";
import PdfView from "../pdfAndXlxView.vue";
import markPdf from "../markPdf.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { mapGetters } from "vuex";
import newPdfView from 'askbot-dragon/src/components/preview/newPositionPreview'
export default {
    data () {
        return {
            fileText: '',
            loading: false,
            source:'',
            imgList: [],
            showPreview: false,
            withLocationMark: false,
            iframeHeight:"100%",
            newVersion:"",
            previewHref:"",
            newFileType:"",
            pageChunkData:[],
            numberPage:0,
            currentPage:0
        }
    },
    components:{PdfView, ElImageViewer, markPdf,newPdfView},
    props: {
        url: {
            type: String,
            required: true,
        },
        fileType: {
            type: String,
            default: '',
        },
        titleValue:{
            type: String,
            default: '',
        },
        tagIds: {
            type: Array,
            default(){
                return []
            }
        },
        knowledgeId:{
          type: String,
          default: '',
        }
    },
    mounted() {
        if (this.fileType === 'IMAGE'){
          document.getElementById('file_pre').style.height = '600px'
        }
        let iframe = document.getElementsByClassName('preview_iframe')[0]
        if(iframe) {
            // this.loading = true
            iframe.onload = () =>{
                this.loading = false;
              this.$emit('loadingSuccess')
            }
        }
        if(this.fileType == 'VIDEO' || this.fileType == 'WECHAT' || this.tagIds.length != 0) {
            this.loading = false
        }

        console.log('fileType',this.fileType )
        window.addEventListener('message',this.handleIframeMessage,false)
    },
    computed: {
        ...mapGetters('knowledge', {
          textWatermarkStr: 'getTextWatermarkStr'
        }),
        previewUrl () {
            let that = this
            that.loading = true;
            let kkviewUrl = process.env.VUE_APP_NODE_ENV == 'production' ? 'https://kkfileview.askbot.cn/onlinePreview?url=' : 'https://test.open.askbot.cn/kkfileview/onlinePreview?url='
            let url = kkviewUrl + this.url + '&officePreviewType=pdf'
            if (this.textWatermarkStr){
              url += '&textWatermarkValue=' + this.textWatermarkStr
            }
            // if (this.url != '') {
            //     if (/[\u4E00-\u9FA5]+/g.test(this.url)) {
            //         url += btoa(encodeURIComponent(this.url))
            //     } else {
            //         url += btoa(this.url)
            //     }
            // }
            url = url + "&timestamp=" + new Date().getTime()
            console.log(url,'url');
            return url
        },
    },
    methods: {
        clickImg(flag) {
            let fn = (e) =>{
                console.log(e, e.target.name);
            }
            let filePre = document.getElementsByClassName('preview_iframe')[0]
            if(flag) {
                console.log(filePre);
                // filePre.onload = () =>{
                //     setTimeout(() =>{
                //         filePre.contentWindow.addEventListener('click', (e) =>{
                //             console.log(e, '1111');
                //         })
                //     }, 500)
                // }
                // filePre.onclick = fn;
            } else {
                window.removeEventListener('click', fn)
            }
        },
        hanledclick(e) {
            console.log(e);
        },
        // 废弃
        getBlob() {
            this.fileText = ''
            this.$http(this.url).then(res =>{
                console.log(res);
                if(res.data) {
                    // this.fileText = res.data
                    this.replaceHref(res.data)
                    // let reg = /<style(([\s\S])*?)<\/style>/g
                    // let list = res.data.match(reg)
                    // if(list.length > 0) {
                    //     console.log(list[0]);
                    //     // let style = document.createElement('style')
                    //     // style.type = 'text/css';
                    //     // let head = document.head || document.getElementsByTagName('head')[0]
                    //     // let cssText = list[0].replace('<style>', '').replace('</style>', '')
                    //     // let textNode = document.createTextNode(cssText);
                    //     // style.appendChild(textNode);
                    //     // head.appendChild(style)
                    // }
                }
            })
        },
        // 废弃
        replaceHref(fileText) {
            let div = document.createElement('div')
            div.innerHTML = fileText
            let arr = []
            let fn = (children) => {
                for (let index = 0; index < children.length; index++) {
                    // console.log(children[index],children[index].getAttribute, 'targettarget');
                    if(children[index].getAttribute('data-replace')) {
                        let uuid = uuidv4()
                        children[index].setAttribute('uuid', uuid)
                        arr.push(children[index].src)
                    }
                    if(children[index].children) {
                        fn(children[index].children)
                    }
                }
            }
            fn(div.children)
            if(arr.length > 0) {
                let httpUrl = requestUrl.preview.previewUrl + '?needEncrypt=false'
                arr.forEach(async (url,index) =>{
                    console.log(url, 'targettarget');
                    await this.$http.post(httpUrl, {
                        fileInOssPath: decodeURIComponent(url)
                    }).then(res =>{
                        if(res.data.code == '0') {
                            fileText = fileText.replace(decodeURIComponent(url),  res.data.data)
                            // console.log(fileText);
                            if(index == arr.length - 1) {
                                this.fileText = fileText
                                this.loading = true
                            }
                            // this.$set(this, 'fileText', this.fileText.replace(url,res.data.data))
                            // console.log(url == this.fileText);
                        }
                    })
                })
            } else {
                this.loading = true
                this.fileText = fileText
            }
        },
        getCkeditorContent(herf) {
            this.source = 'CREATED_BY_ONESELF'
			let url = requestUrl.knowledge.htmlTextDeal + '?onlyText=false'
            this.$http.post(url,{ fileInOssPath: herf, templateId: ''}).then((res) => {
				if (res.data.code == 0) {
					// this.fileText = res.data.data.replaceAll('&lt;', '<')
					// this.fileText = this.fileText.replaceAll('&gt;', '>')
                    // this.fileText = this.fileText.replaceAll('visibility: hidden;', '')

                    let html = res.data.data.replaceAll('&lt;', '<')
                    html = html.replaceAll('&gt;', '>')
                    html = html.replaceAll('visibility: hidden;', '')
                    //  前端对后端旧数据（微信转发文章类型）兼容
                        let div = document.createElement('div')
                        div.innerHTML = html
                        let js_content =  document.getElementById('js_content')
                        js_content && js_content.removeAttribute('class')
                        html = div.outerHTML
                        html = html.replaceAll('rich_media_ meta_text', '')
                        html = html.replaceAll('js_wx_tap_highlight', '')
                        html = html.replaceAll('icon_appmsg_tag', '')
                    //
                    this.fileText = html
                    this.loading = false;
                    this.$emit('loadingSuccess')
                    // this.fileText = this.fileText.replace(`b.src="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='1px' height='1px' viewBox='0 0 1 1' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E%3C/title%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' fill-opacity='0'%3E%3Cg transform='translate(-249.000000, -126.000000)' fill='%23FFFFFF'%3E%3Crect x='249' y='126' width='1' height='1'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/svg%3E"`, `b.src=b.getAttribute('data-src')`)
				} else {
					this.messageFn('error', res.data.message);
				}
			});
        },
        toView() {
            window.open(this.url, this.titleValue, 'width=1000,height=800')
        },
        downLoad(e) {
            if(e.target.nodeName == 'A' ) {
                this.$http.post(
                    '/knowledge-api/temporary-certificate/or-origin?expired=30',
                    e.target.getAttribute('href'),
                    {
                        headers: {
                        "Content-Type": "application/json",
                    }
                }).then(res => {
                    if(res.data) {
                        window.open(res.data,'_blank')
                    }
                })
                e.returnValue = false
            } else if(e.target.nodeName == 'IMG') {
                this.showPreview = true;
                let previewImageUrl = e.target.getAttribute('src')
                let richtext = JSON.parse(JSON.stringify(this.fileText))
                this.imgList = [];
                richtext.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
                    this.imgList.push(capture);
                });
                /*当前点击的图片作为第一个图片*/
                let index = this.imgList.indexOf(previewImageUrl);
                this.imgList.splice(index, 1);
                this.imgList.unshift(previewImageUrl);
                console.log(this.imgList);
            }
        },
        closeViewer() {
            this.showPreview = false;
            this.imgList = [];
        },
        openMark() {
            this.$refs.pdf_view.openMark()
        },
        handleIframeMessage(res){
          if (res.data.type == 'getViewerHeight'){
            let iframeHeight = this.iframeHeight.replace('px','')
            if (res.data.data && iframeHeight != (res.data.data + 50)){
              this.iframeHeight = res.data.data + 50 + 'px'
            }
          }
          if (res.data.type == 'getViewPage' && res.data.data){
            res.data.data = res.data.data.replace("of ", "")
            this.numberPage = Number(res.data.data);
            if (this.pageChunkData.length > 0){
              this.scrollPPTPage(this.pageChunkData[0])
            }
          }
          if (res.data.type == 'getViewerHeight' || res.data.type == 'getViewPage'){
            if (this.pageChunkData.length > 0){
              this.scrollPPTPage(this.pageChunkData[0])
            }
            this.loading = false;
          }
        },
      getOssPath(url){
        let httpUrl = '/knowledge-api/knowledge/getTemporaryCertificate';
        let type = this.getFileType(url);
        this.newFileType = type;
        if (type === 'VIDEO' || type === 'PDF' || type === 'EXCEL' || type === 'TXT' || type === 'DOC') {
          httpUrl += '?needEncrypt=false'
        } else {
          httpUrl += '?needEncrypt=true'
        }
        this.$http.post(httpUrl, {
          "fileInOssPath": url
        }).then(res => {
          console.log('getOssPath',res)
          if (res.data.code == '0'){
            this.previewHref = res.data.data;
          }
        })
      },
      getFileType(url){
        let index = url.lastIndexOf('?')
        let newFileInOssPath =  url.substring(0, url.indexOf("?"))
        if (index == -1){
          newFileInOssPath = url
        }
        const fileType = newFileInOssPath.substring(newFileInOssPath.lastIndexOf('.'));
        if (fileType.toLowerCase() === '.mp4' || fileType.toLowerCase() === '.mov' || fileType.toLowerCase() === '.movc') {
            return 'VIDEO'
        } else if (fileType.toLowerCase() === '.html') {
            return 'HTML'
        } else if (fileType.toLowerCase() === '.png' || fileType.toLowerCase() === '.jpg' || fileType.toLowerCase() === '.jpeg' || fileType.toLowerCase() === '.gif') {
            return 'IMAGE'
        } else if (fileType.toLowerCase() === '.docx'){
            return 'DOC'
        } else if (fileType.toLowerCase() === '.ppt' || fileType.toLowerCase() === '.pptx'){
            return 'PPT'
        }  else if (fileType.toLowerCase() === '.pdf'){
            return 'PDF'
        } else if (fileType.toLowerCase() === '.txt'){
            return 'TXT'
        } else {
            return 'OTHER'
        }
      },
      //判断是否使用新定位组件
      getLocationInfo(){
        this.$http.get('/knowledge-api/knowledge/knowledge-part-location-info/list?ids=' + this.tagIds.join(',')).then(res => {
          console.log('getLocationInfo',res)
          if (res.data.code == 0){
            if (res.data.data && res.data.data.length > 0 && res.data.data[0].extractVersion == 'v3'){
              this.newVersion = true;
              if (this.newFileType !== 'PPT'){
                this.loading = false;
              }
              for (let i=0;i<res.data.data.length;i++){
                if (res.data.data[i].paragraphChunkInfo && res.data.data[i].paragraphChunkInfo.pageChunkData){
                  this.pageChunkData.push(res.data.data[i].paragraphChunkInfo.pageChunkData.page_number)
                }
              }
              this.$nextTick(() => {
                if (this.pageChunkData.length > 1){
                  let iframe = document.getElementsByClassName('preview_iframe')
                  if (iframe && iframe.length > 0){
                    iframe[0].style.paddingBottom = '50px'
                  }
                }
              })
            } else {
              this.newVersion = false;
            }
          }
        })
      },
      currentChange (value) {
        this.currentPage = value - 1;
        this.scrollPPTPage()
      },
      prev(){
        if (this.currentPage == 0){
          return
        }
        this.currentPage--;
        this.scrollPPTPage()
      },
      next(){
        if (this.currentPage === (this.pageChunkData.length - 1)){
          return;
        }
        this.currentPage++;
        this.scrollPPTPage()
      },
      scrollPPTPage(){
        this.$nextTick(() =>{
            setTimeout(() =>{
                console.log(this.numberPage, 'this.numberPage');
                console.log(this.pageChunkData,this.currentPage, this.iframeHeight, 'this.numberPage');
                if (this.numberPage > 0){
                    let pageNumber = this.pageChunkData[this.currentPage];
                    let height = Math.floor(Number(this.iframeHeight.replace('px',"")));
                    let pageHeight = Math.floor(height / this.numberPage);
                    let scrollHeight = (pageNumber - 1) * pageHeight;
                    let dom = document.getElementById('pre_cont');
                    console.log(dom, 'domdomdomdomdom');
                    if (dom){
                        dom.scrollTo(0,scrollHeight)
                        dom.scrollTop = scrollHeight
                    }
                }
            }, 200)
        })
      }
    },
    watch:{
        withLocationMark(value) {
            if(value) {
                this.loading = false
            }
        }
    },
    beforeDestroy() {
      window.removeEventListener('message', this.handleIframeMessage);
    }
}
</script>
<style lang="less" scoped>
.file_pre {
    width: 100%;
    min-height: 400px;
    height: 100%;
    //height: calc(100% - 4px);
    //position: relative;
    visibility: inherit !important;
    .preview_iframe {
        width: 100%;
        //height: 100%;
    }
    .video {
        width: 80%;
        height: 50%;
      text-align: center;
    }
    .preview_wechat {
        width: 100%;
        //height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;
        padding: 16px;
        background-color: #ffffff;
        a {
            color: #136ec2;
            cursor: pointer;
        }
        /deep/ a:link {
            color: #136ec2;
        }
        /deep/ a:visited {
            color: #136ec2;
        }
        /deep/.chat-cell-combine {
            >span {
                .chat-cell {
                    margin-left: 20px;
                }
            }
            .chat-cell-file-content {
                width: calc(100% - 36px);
                .chat-cell-file-info-name {
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .js_blockquote_digest {
                color: #000000 !important;
            }
        }
    }
    /deep/img{
        max-width: 100%;
    }
    /deep/.ne-table{
        width: 100% !important;
    }
}
.loading_img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #FFF;
    img {
        width: 50px;
        height: 50px;
    }
}
.wechat_view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-items: center;
    padding-top: 30%;
    box-sizing: border-box;
    align-items: center;
    p {
        margin-bottom: 15px;
        font-size: 18px;
    }
    .to_link {
        width: 100px;
        height: 30px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #366aff;
        color: #366aff;
        cursor: pointer;
        background: #FFF;
    }
}
.imgaeViewer {
    /deep/ img {
        max-width: 80% !important;
        max-height: 80% !important;
    }
}
#pagination {
  .total-class {
    margin-right: 13px;
    font-weight: 400;
  }
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: white;
  box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
  border-radius: 5px;
  z-index: 1000;
  /deep/.el-pagination {
    margin-right: 110px;
  }
  /deep/.el-pager {
    background: #EDF0F6;
    border-radius: 15px;
  }
  /deep/.el-pagination.is-background .btn-next {
    width: 30px;
    height: 30px;
    background: #EDF0F6;
    border-radius: 50%;
  }
  /deep/.el-pagination .btn-next {
    width: 30px;
    height: 30px;
    background: #EDF0F6;
    border-radius: 50%;
    padding-left: 0;
    margin-left: 5px;
  }
  /deep/.el-pagination .btn-prev {
    width: 30px;
    height: 30px;
    background: #EDF0F6;
    border-radius: 50%;
    padding-right: 0;
    margin-right: 5px;
  }
  /deep/.el-pagination button {
    padding: 0;
    min-width: 30px;
  }
  /deep/.el-pager li {
    background: #EDF0F6;
    height: 30px;
    min-width: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #717b90;
  }
  /deep/.el-pager li:first-child {
    border-bottom-left-radius: 15px !important;
    border-top-left-radius: 15px !important;
  }
  /deep/.el-pager li:last-child {
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }
  /deep/.el-pager li.active {
    width: 30px;
    height: 30px;
    min-width: 30px;
    background: #366AFF;
    border: 3px solid #A1B9FF;
    border-radius: 50%;
    line-height: 24px;
    color: white;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #366AFF;
  }
}
</style>
<style lang="less">
.el-image-viewer__close {
    color: #FFF;
}
</style>
